<template>
  <div ref="about" class="container-fluid">
    <!-- big-title -->
    <h1 class="sr-only">회사소개</h1>

    <div class="summary">
      <div class="value-three-background">
        <b-img
          alt=""
          aria-hidden="true"
          class="value-three-background-img unselectable"
          src="../assets/about/values-bg.png"
        ></b-img>
      </div>
      <div class="big-heading-wrapper">
        <h2 class="big-heading">사람과 영화의 건강한 만남</h2>
        <div class="about-description-div">
          <h3 class="about-description-title">
            씨네소파는 자본에 구애받지 않는 영화와 배급을 생각합니다.
          </h3>
          <p class="about-description-paragraph">
            혐오를 이용하는 영화가 아니라 우리 삶의 다양한 면면으로 관객을 초대하는 작품을 찾습니다.
            (과 함께 합니다.) 세상이 내린 정답(편견)을 공고히 하는 것이 아니라 한번쯤 의심해보게
            만드는 안티-자본적 영화를 발굴합니다. 그리고 자본이 아니라 오직 사려 깊은 마음으로
            영화와 당신을 잇습니다.
          </p>
        </div>
        <div class="about-description-div">
          <h3 class="about-description-title">
            씨네소파는 인생을 변화시키는 향유의 체험을 지향합니다.
          </h3>
          <p class="about-description-paragraph">
            중요한 것은 갯수가 아니라 깊이(depth). 한편의 영화를 통한 깊이 있고 충분한 향유는 백편의
            영화보다 낫습니다. 좋은 영화보기를 통해 우리는 자신을 들여다보고 타인의 존재를 감각하며,
            그 사이를 메우는 심연과 조우합니다. 나아가 세계와 조우합니다. 킬링 타임이 아닌 필링
            타임의 예술로 당신의 삶을 충전합니다.
          </p>
        </div>
        <div class="about-description-div">
          <h3 class="about-description-title">
            씨네소파는 사람이 살아나는 건강한 일터를 꿈꿉니다.
          </h3>
          <p class="about-description-paragraph">
            삶의 각박함으로 인해 개개인이 인생의 크고 작은 기회를 포기하지 않도록 탄탄하고 유연한
            일터를 만듭니다. 다름으로 인한 우리 사이의 간극을 인정하고 자원을 불공평하게 분배하여
            진정한 공정을 실현합니다. 자기존재를 지워야만 하는 곳이 아니라 각자의 강점을 발굴하는
            즐거운 조직으로서, 사람이 떠나가는 로컬에서 건강한 일자리를 만듭니다.
          </p>
        </div>
      </div>
      <div class="big-heading-wrapper cine-moto-div">
        <h2 class="cine-moto-heading">씨네소파 모토</h2>
        <p class="cine-moto-p">
          사람, 사물, 세계. 그 사이의 무수한 점프컷을 존중할 것.<br />
          그러나 올바름을 알아볼 것.
        </p>
      </div>
      <div class="big-heading-wrapper about-description-div">
        <h2 class="big-heading">씨네소파 5대 핵심가치</h2>
        <div class="five-div">
          <h3 class="five-title">분배의 어소시에이션</h3>
          <p class="five-p">
            각자에게 주어진 다름이 만드는 불공평을 해소할 수 있도록 자원을 차등 분배한다.<br />
            같은 출발선을 만든다.
          </p>
        </div>
        <div class="five-div">
          <h3 class="five-title">업무적 효율성(프로페셔널)</h3>
          <p class="five-p">
            불필요하게 에너지가 소모되지 않도록 효율적인 업무 시스템을 구축한다.<br />이를 위해
            끊임없이 업무 방식과 방향, 결과물을 고민하며 일한다.
          </p>
        </div>
        <div class="five-div">
          <h3 class="five-title">상생하는 파트너십(투명, 협력)</h3>
          <p class="five-p">
            함께 일하는 이에게 과정과 결과를 투명하게 공유하여 건강한 파트너십을 만든다.<br />서로의
            역량을 믿고 상생하는 협력을 구축한다.
          </p>
        </div>
        <div class="five-div">
          <h3 class="five-title">실력</h3>
          <p class="five-p">
            공동의 철학을 내재화하고 선택의 기준을 합의하기 위해 모든 소파서퍼가 함께 공부한다.<br />
            실력(실제로 갖추고 있는 힘)을 쌓아 일의 퀄리티를 높인다.
          </p>
        </div>
        <div class="five-div">
          <h3 class="five-title">유연한 태도</h3>
          <p class="five-p">
            급변하는 상황과 외부 환경에 당황하지 말자.<br />차분하고 유려하게 파도를 탄다
          </p>
        </div>
      </div>
      <!-- <div class="simple-about-wrapper">
        <div class="simple-about">
          <p class="reveal1-1">씨네 + 소파 =</p>
          <p class="reveal1-2">
            <span class="big">{</span> 씨네(<small>cine-</small>): 영화의
            <span class="big">}</span> +
            <span class="big">{</span> 소파(<small>小波</small>) 작은 파도
            <span class="big">}</span>
          </p>
          <p><br /></p>
          <p class="reveal1-3">우리의 작은 파도는,</p>
          <p class="reveal1-4 simple-about-list">
            <em>1.</em> 상영 기회가 없던
            <span class="blue-circle-strong">좋은 영화</span>를 발견하여 관객과
            만나게 합니다.
          </p>
          <p class="reveal1-5 simple-about-list">
            <em>2.</em> 영화를 재밌고 깊이
            <span class="blue-circle-strong">향유</span>할 수 있는 방법을
            고민합니다.
          </p>
          <p class="reveal1-6 simple-about-list">
            <em>3.</em> 다재다능한 친구들과 영화를 매개로
            <span class="blue-circle-strong">함께</span>하고자 합니다.
          </p>
          <p><br /></p>
          <p class="reveal1-7">씨네소파는 시야를 확장시키는 영화를 통해</p>
          <p class="reveal1-8">
            나 자신을 발견하고, 상대를 헤아리며, 세상을 이해하는 방법을
            제시합니다.
          </p>
        </div>
      </div> -->
    </div>
    <!-- 
    <div class="big-title d-flex justify-content-center">
      <div class="big-title-letter">
        <div class="big-title-letter-up">
          씨네
        </div>
        <div class="big-title-letter-down">
          Cine-; 영화의
        </div>
      </div>
      <span class="big-title-plus">+</span>
      <div class="big-title-letter">
        <div class="big-title-letter-up">
          소파
        </div>
        <div class="big-title-letter-down">
          小波; 작은 파도
        </div>
      </div>
    </div>

    excerpt
    <div class="excerpt d-none">
      씨네소파는 숨겨져 있던 영화를<br />
      관객에게 실어 보내는 파도입니다.
    </div>

    value three
    <div class="value-three">
      <div class="value-three-background">
        <b-img
          class="value-three-background-img unselectable"
          src="../assets/about/values-bg.png"
        ></b-img>
      </div>
      <div class="value-three-prefix">우리의 작은 파도는</div>
      <div class="value-three-main d-flex justify-content-center">
        <div class="blue-circle">
          <p>
            상영 기회가 없던<br /><span class="blue-circle-strong">좋은 영화</span>를 발견하여<br />관객과
            만나게 합니다.
          </p>
        </div>
        <div class="blue-dots">
          <div class="blue-dot"></div>
          <div class="blue-dot"></div>
          <div class="blue-dot"></div>
        </div>
        <div class="blue-circle">
          <p>
            영화를 재밌고<br />깊이 <span class="blue-circle-strong">향유</span>할 수 있는<br />방법을
            고민합니다.
          </p>
        </div>
        <div class="blue-dots">
          <div class="blue-dot"></div>
          <div class="blue-dot"></div>
          <div class="blue-dot"></div>
        </div>
        <div class="blue-circle">
          <p>
            다재다능한 친구들과<br />영화를 매개로<br /><span class="blue-circle-strong">함께</span
            >하고자 합니다.
          </p>
        </div>
      </div>
      <div class="value-three-affix">
        <div class="value-three-affix-bg"></div>
        <div class="value-three-affix-sub">씨네소파는 시야를 확장시키는 영화를 통해</div>
        <div class="value-three-affix-main">
          씨네소파는 시야를 확장시키는 영화를 통해<br />
          나 자신을 발견하고, 상대를 헤아리며,<br />
          세상을 이해하는 방법을 제시합니다.
        </div>
      </div>
    </div> -->

    <!-- works -->
    <div class="big-heading-wrapper">
      <h2 class="big-heading">씨네소파가 하는 일</h2>
    </div>
    <div class="works" data-aos="fade-up" data-aos-duration="1000">
      <div class="works-bg"></div>
      <div class="works-circles">
        <div class="works-circle-n">
          <div>
            <div class="works-circle-n-title">독립예술영화<br/> 배급</div>
            <div class="works-circle-n-description">
              극장개봉, 공동체상영,<br />
              기타부가판권 등
            </div>
          </div>
        </div>
        <div class="works-circle-n works-circle-2">
          <div>
            <div class="works-circle-n-title">영화 매개 문화예술<br />향유사업</div>
            <div class="works-circle-n-description">
              영화 향유 콘텐츠, <br />교육 사업
            </div>
          </div>
        </div>
        <div class="works-circle-n">
          <div>
            <div class="works-circle-n-title">지역 커뮤니티 및 <br />문화예술 사업</div>
            <div class="works-circle-n-description">문화 예술 용역 사업</div>
          </div>
        </div>
        <div class="works-circle-n">
          <div>
            <div class="works-circle-n-title">공간 거점 <br/>사업</div>
            <div class="works-circle-n-description">공간 거점 문화/ 커뮤니티 사업</div>
          </div>
        </div>
        <!-- <div class="works-circle">영화<br />배급/마케팅</div>
        <div class="works-circle">영화<br />배급/마케팅</div>
        <div class="works-circle">영화향유플랫폼<br />소파섬</div>

        <div class="works-circle">홍보 및<br />행사 대행</div> -->
      </div>
    </div>
    <!-- <div class="works" data-aos="fade-up" data-aos-duration="1000">
      <div class="works-bg"></div>
      <div class="works-circles">
        <div class="works-circle">영화<br />배급/마케팅</div>
        <div class="works-circle">영화향유플랫폼<br />소파섬</div>
        <div class="works-circle">홍보 및<br />행사 대행</div>
      </div>
    </div> -->

    <!-- people -->
    <div class="big-heading-wrapper">
      <h2 class="big-heading">함께하는 사람들</h2>
    </div>
    <div class="people row">
      <div
        class="person col-12 col-md-6"
        v-for="(person, index) in people"
        :key="index"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="person-sign">
          <b-img
            class="person-sign-image"
            :src="parseUploadLink(person.imageLink)"
            :alt="person.imageAlt"
          ></b-img>
        </div>
        <div class="person-info">
          <div class="person-name-position">
            <div class="person-name-position-wrapper">
              <div class="person-name">
                <span class="person-real-name">{{ person.name }}</span>
                <span class="person-nickname"> {{ person.nickname }}</span>
              </div>
              <div class="person-position">
                <template v-if="person.position !== ''">
                  {{ person.position }}
                  <span class="position-seperator">|</span>
                </template>

                {{ person.work }}
              </div>
            </div>
          </div>
          <div class="person-contact">
            <div class="person-phone">
              <span class="person-contact-title">m</span>
              <span class="person-contact-content">{{ person.phone }}</span>
            </div>
            <div class="person-email">
              <span class="person-contact-title">e</span>
              <span class="person-contact-content">{{ person.email }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- history -->
    <div class="big-heading-wrapper">
      <h2 class="big-heading">씨네소파가 걸어온 길</h2>
    </div>
    <div class="history">
      <div class="history-bg"></div>
      <div
        class="history-year-row"
        v-for="(yearGroup, index) in formattedHistory"
        :key="index"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="history-year">{{ yearGroup.year }}</div>
        <div class="history-events">
          <div class="history-event" v-for="(event, index) in yearGroup.events" :key="index">
            {{ event.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="big-heading-wrapper">
      <h2 class="big-heading">씨네소파 인재상</h2>
      <div class="about-description-div">
        <p class="insta-div">
          🏄‍♂️ ‘소파서퍼' 🏄‍♂️ 단단한 코어근력(건강한 정신과 몸)을 가지고 유연하게 물(세상, 타자)을 타며
          <br />
          맞서는 용감한 소파서퍼 소파서퍼의 일상은 인스타그램
          <a href="https://www.instagram.com/we_are_sopasurfer/"
            ><span class="insta-link">@we_are_sopasurfer</span></a
          >에서 확인하세요😊
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from 'aos';
import { graphql, siteOptionsQuery } from '@/graphql-client';
import { parseUploadLink } from '../util';

export default {
  name: 'About',
  data() {
    return {
      people: [],
      history: [
        {
          date: new Date('2019-05-03'),
          content: '영화展; 기억할 만한 지나침[머무는 집] 전시',
        },
        {
          date: new Date('2019-04-03'),
          content: '<기억할 만한 지나침> 전국 개봉',
        },
        {
          date: new Date('2019-03-03'),
          content: '<밤의 문이 열린다> 전국 개봉',
        },
        {
          date: new Date('2019-02-03'),
          content: '높아서 재송 ‘재송동 사무실’시대 개막',
        },
        {
          date: new Date('2019-01-03'),
          content: '부산독립영화제 순회상영회 <단, 비>진행',
        },
        { date: new Date('2018-06-03'), content: '<마담B> 전국 개봉' },
        {
          date: new Date('2018-05-03'),
          content: '시민배급단 씨네보배 1기 운영',
        },
        {
          date: new Date('2018-04-03'),
          content: '부산형 예비사회적 기업 지정',
        },
        {
          date: new Date('2018-03-03'),
          content: '배급기록집 [소파섬] 1호 출간',
        },
        {
          date: new Date('2018-02-03'),
          content: '관객리뷰단 씨네보쓰 2기 운영',
        },
        {
          date: new Date('2018-01-03'),
          content: '<파란입이 달린 얼굴> 전국 개봉',
        },
        {
          date: new Date('2017-05-03'),
          content: '배급기록집 [소파섬] 0호 출간',
        },
        {
          date: new Date('2017-04-03'),
          content: '관객리뷰단 씨네보쓰 1기 운영',
        },
        {
          date: new Date('2017-03-03'),
          content: '<그럼에도 불구하고> 전국 개봉',
        },
        {
          date: new Date('2017-02-03'),
          content: '영화배급협동조합 씨네소파 설립',
        },
        {
          date: new Date('2017-01-03'),
          content: '영화배급협동조합 씨네소파 창립총회',
        },
      ],
    };
  },
  computed: {
    /** @returns {object} */
    formattedHistory() {
      const yearKeyed = {};
      this.history.forEach((event) => {
        const year = event.date.getFullYear();
        if (yearKeyed[year] === undefined) yearKeyed[year] = [];
        yearKeyed[year].push(event);
      });
      // const result = [];
      const result = Object.keys(yearKeyed)
        .sort()
        .reverse()
        .map((year) => ({
          year,
          events: yearKeyed[year].sort((a, b) => b.date.getTime() - a.date.getTime()),
        }));
      return result;
    },
  },
  async mounted() {
    // console.log(AOS);
    AOS.init();
    const res = await graphql(siteOptionsQuery, {
      names: ['person', 'history'],
    });
    const result = res.data.siteOptions;
    // console.log('# About = mounted');
    // console.log(result);
    this.people = result[0].value.map((item) => ({
      name: item.name,
      nickname: item.nickname,
      position: item.position,
      work: item.work,
      email: item.email,
      phone: item.phone,
      // eslint-disable-next-line global-require
      imageLink: item.image.fileurl,
      imageAlt: item.image.alt,
    }));
    this.history = result[1].value.map((item) => ({
      ...item,
      date: new Date(item.date),
    }));
  },
  methods: {
    parseUploadLink,
  },
};
</script>

<style lang="scss" scoped>
@use '../util.scss';

$total: 9;

@for $i from 1 to $total {
  .reveal1-#{$i} {
    animation: 0.8s ease $i * 100ms slide-up both;
  }
}
@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.simple-about {
  font-size: 18px;
  font-weight: 500;
  // max-width: 700px;
  // margin: 0 auto;
  position: relative;
  z-index: 2;
  & small {
    font-size: 95%;
  }
  & .big {
    font-size: 120%;
    font-weight: 400;
  }
  & p {
    position: relative;
    z-index: 2;
  }
}

.works-circle-n {
  // flex-shrink: 0;
  flex-grow: 0;
  width: 250px;
  text-align: center;
  // font-size: 28px;
  line-height: 1.3;
  color: #009eda;
  // font-weight: 500;
  padding: 100px 0 150px;
  height: 300px;
  display: flex;
  justify-content: center;
  // align-items: center;
}

.works-circle-2 {
  // transform: translateY(-1rem);
}

.works-circle-n-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.works-circle-n-description {
  font-size: 16px;
  color: #000000;
}

.simple-about-wrapper {
  display: flex;
  justify-content: center;
}

.about-description-div {
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.about-description-title {
  display: flex;
  font-size: 18px;
  justify-content: center;
  margin-bottom: 1rem;
}
.about-description-paragraph {
  display: flex;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto;
}

.big-title {
  position: relative;
  z-index: 2;
  margin: 80px 0 0;
}

.insta-link {
  font-weight: 700;
}
.cine-moto-div {
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.cine-moto-p {
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.cine-moto-heading {
  text-align: center;
  display: block;
  color: #009eda;
  padding-top: 32px;
  padding-bottom: 16px;
  font-size: 30px;
  // border-top: 1px solid #009eda;
}

.five-div {
  margin: 0 auto;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.five-title {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.five-p {
  text-align: center;
}

.insta-div {
  padding-left: 2rem;
  padding-right: 2rem;
}
/* Small (sm:) */
@media (min-width: 640px) {
  .about-description-div {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
  .about-description-title {
    display: flex;
    font-size: 22px;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .about-description-paragraph {
    display: flex;
    justify-content: center;
    padding-left: 10rem;
    padding-right: 10rem;
    margin: 0 auto;
  }

  .big-title {
    position: relative;
    z-index: 2;
    margin: 80px 0 0;
  }

  .insta-link {
    font-weight: 700;
  }
  .cine-moto-div {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }

  .cine-moto-p {
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  .cine-moto-heading {
    text-align: center;
    display: block;
    color: #009eda;
    padding-top: 32px;
    padding-bottom: 16px;
    font-size: 38px;
    // border-top: 1px solid #009eda;
  }

  .five-div {
    margin: 0 auto;
    margin-bottom: 2rem;
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .five-title {
    display: flex;
    justify-content: center;
    font-size: 24px;
  }

  .five-p {
    text-align: center;
  }
}

.big-title-letter-up {
  font-size: 24px;
  font-weight: 700;
}

.big-title-letter-down {
  color: util.$text-secondary-color;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -1px;
  text-align: center;
  margin: 0 -20px 0;
}
.big-title-plus {
  font-size: 24px;
  font-weight: 700;
  color: util.$text-secondary-color;
  padding: 0 30px;
}

.excerpt {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 50px 0;
  margin-bottom: 50px;
  letter-spacing: -0.5px;
}

.value-three {
  position: relative;
  margin: 0 -15px;
}
.value-three-prefix {
  position: relative;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 50px 0 20px;
  color: util.$link-color;
}

.value-three-main {
  position: relative;
  // margin: 30px 0;
  padding: 0 0 30px;
}

// .value-three-background {
//   position: absolute;
//   transform: translateX(min(calc((100vw - 1260px) / -2), 0.000000000000000001px));
//   // background-color: #ddd;
//   width: 100vw;
//   height: 100%;
// }

// [data-v-039c5b43]
.value-three-background {
  height: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// .value-three-background-img {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   top: -300px;
// }

.value-three-background-img {
  position: relative;
  top: -300px;
}

.blue-circle {
  text-align: center;
  color: util.$text-color;
  // border-bottom: 1px solid util.$link-color;
  margin: 0 20px;
  // background-color: #fff;
  padding: 0 20px;
  // width: 200px;
  // height: 200px;
  // border-radius: 300px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.4px;
  justify-content: center;
  align-items: center;
}
.blue-circle-strong {
  font-weight: 700;
  // font-size: 21px;
}
.blue-circle p {
  // padding-bottom: 100%;
  margin: 0;
}
.blue-dots {
  display: flex;
  align-items: center;
  margin: 8px;
}
.mobile .boue-dots {
  display: none;
}

.blue-dot {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin: 2px;
  background-color: #009eda;
  // background-color: var(--link-dark-color);
}
.value-three-affix {
  position: relative;
  padding: 80px 0 30px;
}

.value-three-affix-bg {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 120%;
  background: radial-gradient(rgba(255, 255, 255, 1) 0%, transparent 50%);
}

.value-three-affix-sub {
  position: relative;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  color: #009eda;
  // color: #009eda;
}

.value-three-affix-main {
  position: relative;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
}

/* BIG HEADING */

.big-heading-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
}
.big-heading-wrapper::before {
  content: '';
  background-color: #009eda;
  display: block;
  position: absolute;
  width: 360px;
  height: 1px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.big-heading {
  text-align: center;
  display: block;
  color: #009eda;
  margin: 60px auto;
  font-size: 38px;
  // border-top: 1px solid #009eda;
}

.mobile .big-heading {
  font-size: 30px;
}
/* PEOPLE */

.people {
  max-width: 1000px;
  margin: 0 auto;
}

.person {
  padding: 50px;
}
.mobile .person {
  padding: 25px 0;
}

.person-sign {
  // text-align: center;
  // background-color: #eee;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.person-sign-image {
  max-width: 100%;
  max-height: 100%;
  min-width: 1px;
  min-height: 1px;
  padding: 30px;
}

.person-info {
  display: flex;
}
.mobile .person-info {
  font-size: 90%;
  margin-left: -20px;
}

.person-name-position,
.person-contact {
  // flex: 0 0 auto;

  width: 50%;
}

.person-name-position {
  // padding-left: 60px;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  // text-align: right;
}

.person-name-position-wrapper {
}
.person-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.person-real-name {
  // font-size: 28px;
  font-size: 1.75em;
  font-weight: 600;
}

.person-nickname {
  // font-size: 20px;
  font-size: 1.25em;
  font-weight: 500;
}

.person-position {
  margin-top: -5px;
  margin-left: 1px;
}
.person-phone,
.person-email {
  border-left: 1px solid util.$link-color;
  padding-left: 20px;
  // font-size: 15px;
  font-size: 0.875em;
}
.person-email {
  font-size: 0.8em;
}

.person-phone {
  padding-bottom: 5px;
}

.person-contact-title {
  font-weight: 600;
  width: 20px;
  display: inline-block;
}

.person-contact-content {
  color: util.$text-secondary-color;
}
/* WORKS */

.works {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 100px;
}

.works-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: center url('../assets/about/works-bg.png') no-repeat;
  background-size: auto 515px;
  background-position: center top -90px;
}

.works-circles {
  display: flex;
  position: relative;
  justify-content: center;
}
.mobile .works-circles {
  flex-direction: column;
  align-items: center;
}

.works-circle {
  // flex-shrink: 0;
  flex-grow: 0;
  width: 250px;
  text-align: center;
  font-size: 28px;
  line-height: 1.3;
  color: #009eda;
  font-weight: 500;
  padding: 100px 0 150px;
}

.mobile .works-circle {
  padding: 40px 0;
  font-size: 22px;
}

// history
.history {
  margin-top: 40px;
  position: relative;
}

.history-bg {
  // background: url('../assets/about/vertical-wave.svg') repeat-y;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
  // top: 10px;
  // background-position: left 92px top 0px;
  // max-width: 600px;
  // width: 100%;
  // height: calc(100% - 20px);
  // background-size: auto 461px;
}

.history-year-row {
  display: flex;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
.history-year {
  color: #009eda;
  font-weight: 600;
  font-size: 32px;
  line-height: calc(100% + 3px);
  padding-right: 30px;
  border-style: solid;
  border-image: linear-gradient(to bottom, util.$link-color 0%, #fff 80%);
  border-image-slice: 1;
  border-image-width: 0 1px 0 0;
}
.mobile .history-year {
  font-size: 22px;
}
.history-events {
  padding-left: 30px;
  margin-bottom: 30px;
}
.history-event {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 5px;
}

.mobile .history-event {
  font-size: 14px;
}

.unselectable {
  user-select: none;
  pointer-events: none;
  // user-drag: none;
  // user-drag: none;
}

.mobile {
  .simple-about {
    font-size: 15px;
  }

  .big-heading-wrapper::before {
    width: 200px;
  }
  .simple-about-list {
    width: 244px;
  }
}
</style>

<style></style>
